/**
 * Class that will wrap all API calls to Services for Navigation Purposes
 */
class NavApiManager {


    /**
     * Gets Navigation Items based on the User Info Provided.
     * @param userInfo
     * @constructor
     */
    public GetMenuNavigationByUser(userInfo: object) {
        let stub = [
            {
                "code": "home",
                "description": "Dashboard",
                "page": "home"
            },
            {
                "code": "stud",
                "description": "Students",
                "page": "students"
            },
        ]
        return new Promise(resolve => {
            resolve(stub);
        });
    }
}

export default NavApiManager