import Home from "./Home";
import {withAuthenticator} from "@aws-amplify/ui-react";
import PropTypes from "prop-types";

const HomeContainer = ({userInfo}) => {

    return (
        <div>
            <Home userInfo={userInfo}/>
        </div>
    )
}
export default  withAuthenticator(HomeContainer);

HomeContainer.propTypes = {
  userInfo: PropTypes.any.isRequired
}