import {Grid} from "@mui/material";
import MaterialTable from "material-table";

export default function StudentGrid({studentSummaries,navViewProfile}) {

    const columns = [
        {title: "First Name", field: "firstName"},
        {title: "Last Name", field: "lastName"},
        {title: "Primary Caregiver", field: "primaryCareGiver"}
    ]

    const actions =
        [
            {
                "icon": 'preview',
                "toolTip": 'View Student Information',
                onClick: (event, rowData) => {
                    navViewProfile(rowData.studentId)
                }
            }
        ]


    return (

        <Grid container={true} direction={"column"}>
            <Grid item>
                <MaterialTable actions={actions} title="Students" columns={columns} data={studentSummaries}/>
            </Grid>
        </Grid>


    )

}