import * as React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import {Auth} from "aws-amplify";
import {useNavigate, Outlet} from "react-router-dom";
import {Grid} from "@mui/material";


const drawerWidth = 150;

function OsNav({window, navItems, navigateHandler}) {

    const [mobileOpen, setMobileOpen] = React.useState(false);
    const navigate = useNavigate();


    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const handSignOut = async () => {
        await Auth.signOut();
        navigate("../", {replace: true});
    }


    const drawer = (
        <div>
            <Typography>
                Logo here
            </Typography>
            <Toolbar/>
            <Divider/>
            {navItems &&
            <List>
                {navItems.map((text, index) => (
                    <ListItem button key={text.code} onClick={e => navigateHandler(text, e)}>
                        <ListItemText primary={text.description}/>
                    </ListItem>
                ))}
            </List>
            }
        </div>
    );

    const container = window !== undefined ? () => window().document.body : undefined;

    return (
        <Box sx={{display: 'flex'}}>
            <CssBaseline/>
            <AppBar className="app-bar"
                    position="fixed"
                    sx={{
                        width: {sm: `calc(100% - ${drawerWidth}px)`},
                        ml: {sm: `${drawerWidth}px`},
                    }}
            >
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{mr: 2, display: {sm: 'none'}}}
                    >
                        <MenuIcon/>
                    </IconButton>
                    <Typography variant="h6" noWrap component="div">
                        Your Own-Spectrum
                    </Typography>

                </Toolbar>
                <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    edge="end"
                    onClick={handSignOut}
                    sx={{mr: 2, display: {sm: 'none'}}}
                >
                    Sign Out
                </IconButton>
            </AppBar>
            <Box
                component="nav"
                sx={{width: {sm: drawerWidth}, flexShrink: {sm: 0}}}
                aria-label="mailbox folders"
            >
                {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                <Drawer
                    container={container}
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                        display: {xs: 'block', sm: 'none'},
                        '& .MuiDrawer-paper': {boxSizing: 'border-box', width: drawerWidth},
                    }}
                >
                    {drawer}
                </Drawer>
                <Drawer
                    variant="permanent"
                    sx={{
                        display: {xs: 'none', sm: 'block'},
                        '& .MuiDrawer-paper': {boxSizing: 'border-box', width: drawerWidth},
                    }}
                    open
                >
                    {drawer}
                </Drawer>
            </Box>
            <Box
                component="main"
                sx={{flexGrow: 1, p: 3, width: {sm: `calc(100% - ${drawerWidth}px)`}}}
            >

                <Toolbar/>

                <Grid container={true} className="app-content">
                    <Grid item xs={12} >
                        <Outlet/>
                    </Grid>
                </Grid>


            </Box>


        </Box>

    );
}

OsNav.propTypes = {
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window: PropTypes.func,
};

export default OsNav;