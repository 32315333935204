import {useEffect, useState} from "react";
import StudentsApiManager from "../StudentsApiManager";
import {Grid} from "@mui/material";
import StudentGrid from "./StudentGrid";
import '../../os-sec.css'
import {useNavigate} from "react-router-dom";

export default function StudentsSummaryContainer({userInfo}){

    const [studentSummaries,setStudentSummaries] = useState([]);
    const navigate = useNavigate();
        useEffect(() => {
        let apiManager = new StudentsApiManager();
        apiManager.GetStudentSummaryByUserId(userInfo).then(value => {
            setStudentSummaries(value)
        })
    },[userInfo]);

        const navViewProfile = (studentId) => {
            console.log("mike" & studentId);
            navigate(`/myown/student/${studentId}`, { replace: true });
        }

        return (
            <Grid container={true} xs={12}>
                <Grid item xs={12}>
                    <StudentGrid studentSummaries={studentSummaries} navViewProfile={navViewProfile}/>
                </Grid>
            </Grid>
        )


}