import {Grid, Paper} from "@material-ui/core";
import {Button, Typography} from "@mui/material";


export default function StudentCareGivers({studentInfo}) {

    const headCells = [
        {
            id: 'firstName',
            numeric: false,
            disablePadding: true,
            label: 'First Name',
        },
        {
            id: 'lastName',
            numeric: true,
            disablePadding: true,
            label: 'Last Name',
        },
        {
            id: 'roleDesc',
            numeric: false,
            disablePadding: true,
            label: 'Role',
        }
    ]


    return (
        <Paper elevation={2}>
            <Grid container direction="row">
                <Grid item xs={12}>
                    <Typography variant="h6" gutterBottom component="div" xs={12} paddingLeft="10px">
                        Team
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Grid container direction={"row-reverse"}>
                        <Grid item xs={6}>
                            <Button
                                name="Add New"
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Button
                                name="Edit Team"
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>

                </Grid>

            </Grid>
        </Paper>

    )

}