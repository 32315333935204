
/**
 * Class that will wrap all API calls to Services for Student Purposes
 */
class StudentsApiManager {

    /**
     * Gets a List of Students Based on the UserID provided
     * @param userId
     * @constructor
     */
    public GetStudentSummaryByUserId(userId: string) {
        let stub = [{
            "studentId": 1,
            "firstName": "Owen",
            "lastName": "Robotham",
            "primaryCareGiver": "Lisa Colbert"
        },
            {
                "studentId": 2,
                "firstName": "John",
                "lastName": "Smith",
                "primaryCareGiver": "Lisa Colbert"
            }

        ];
        return new Promise(resolve => {
            resolve(stub);
        });
    }

    /**
     * Gets a List of Students Based on the UserID provided
     * @param userId
     * @constructor
     */
    public GetStudentInfoByStudentId(studentId: string) {
        let stub = {
            "studentId": 1,
            "firstName": "Owen",
            "lastName": "Robotham",
            "primaryCareGiver": "Lisa Colbert",
            "dateOfBirth" : "07/06/2017",
            "address" : {
                "street": "9 CandleWood Rd",
                "lineTwo": "",
                "city": "Burlington",
                "state": "CT",
                "zipCode": "06013"
            },
            "generalNotes" : "Owen is a good boy , and he likes to run around and jump. His dad is a pain.",
            "img" :{},
            "careGivers" : [ {
                "id":12345 ,
                "firstName" : "Lisa",
                "lastName" : "Colbert",
                "roleCd" : "PRIM",
                "roleDesc" : "Primary"
            },
                {
                    "id":4444 ,
                    "firstName" : "Mitashi",
                    "lastName" : "Robotham",
                    "roleCd" : "PAR",
                    "roleDesc" : "Parent"
                },
                {
                    "id":7777 ,
                    "firstName" : "Haley",
                    "lastName" : "Lane",
                    "roleCd" : "HSC",
                    "roleDesc" : "Home Support"
                },
            ]

        }
        return new Promise(resolve => {
            resolve(stub);
        });
    }



}


export default StudentsApiManager;

