import {Avatar, Grid, Typography} from "@mui/material";
import introIMg from '../../imgs/owen.jpg';

export default function PageNotFound() {


    return (
        <Grid container xs={12} spacing={3} justifyContent="center"  direction={"column"} alignItems="center" className="center-screen-content">
                <Grid item xs={3}>
                    <Avatar src={introIMg}   sx={{ width: 250, height: 250 }} />
                </Grid>
            <Grid item xs={3}>
                <Typography variant="h5">
                    That's right.. I am a little bear....
                </Typography>
            </Grid>
            <Grid item xs={3}>
                <Typography variant="h5">
                    and whatever your looking for is not here.
                </Typography>
            </Grid>
        </Grid>
    )
}