import {Grid} from "@material-ui/core";
import StudentInfo from "./StudentInfo";
import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import StudentsApiManager from "../StudentsApiManager";
import {Typography} from "@mui/material";
import {useFormik} from "formik";
import * as yup from 'yup'
import StudentCareGivers from "./StudentCareGivers";


function FormSetup(schema,studentInfo) {

    const formik = useFormik({
        initialValues: studentInfo,
        enableReinitialize: true,
        validationSchema: schema,
        onSubmit: (values) => {
            console.log(values)
        },
    });
    return formik;
}


export default function StudentInfoContainer({userInfo}) {

    const {studentId} = useParams();
    const [studentInfo, setStudentInfo] = useState({
        firstName :'',
        lastName : '',
        address: {
            lineTwo: ''
        }
    });
    const studentSchema = yup.object({
        firstName: yup
            .string("Enter the First Name of the Student")
            .required("First Name is Required"),
        lastName: yup
            .string("Enter the last name of the Student")
            .required("Last name is required"),

    })


       const form = FormSetup(studentSchema,studentInfo);


    useEffect(() => {
        let apiManager = new StudentsApiManager();
        apiManager.GetStudentInfoByStudentId(studentId).then(value => {
            setStudentInfo(value)
        })

    }, [studentId])

    return (
        <Grid container className="app-content" spacing={2}>
            {studentInfo &&
            <Grid item xs={12}>
                <StudentInfo studentInfo={studentInfo} studentForm={form}/>
            </Grid>
            }
            {studentInfo &&
            <Grid item xs={12}>
                <StudentCareGivers studentInfo={studentInfo}/>
            </Grid>
            }
        </Grid>
    )
}