import {Link} from "react-router-dom";
import * as React from "react";
import * as PropTypes from "prop-types";


const Home = ({userInfo}) => {

    return (
        <div>
            <h1>This is the home page</h1>
            {userInfo && (
                <div>
                <h2>Your Access JWT token is : {userInfo.jwtToken}</h2>
                <h2>Your ID Token Is : {userInfo.idToken}</h2>
                </div>)
            }
            <Link to="/">Home</Link>;
        </div>);

}

export default Home;

Home.propTypes = {
    userInfo: PropTypes.object
}