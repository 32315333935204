import OsNav from "./OsNav";
import NavApiManager from "./NavApiManager";
import {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {useNavigate} from "react-router-dom";


export default function OsNavContainer ({userInfo}) {
    const [navItems,setNavItems] = useState();
    const navigate = useNavigate();
    const handleNav =(navDest) => {
        navigate(`../myown/${navDest.page}`, { replace: true });
    }
    useEffect(() => {
        let apiManager = new NavApiManager();
        apiManager.GetMenuNavigationByUser(userInfo).then(menuInfo => {
            setNavItems(menuInfo);
        })
    },[userInfo]);

    return (
        <div>
            <OsNav navItems={navItems}  navigateHandler={handleNav}/>
        </div>
    )

}

OsNavContainer.propTypes = {
  userInfo: PropTypes.object
}