import {Grid, Paper} from "@material-ui/core";
import {Avatar, TextField, Typography} from "@mui/material";
import introIMg from '../../../../imgs/owen.jpg'
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import MobileDatePicker from '@mui/lab/MobileDatePicker';
import {LocalizationProvider} from "@mui/lab";


export default function StudentInfo({studentInfo, studentForm}) {

    const formSetup = (fieldName, onChangeOverRide,valueOverRide) => {
        console.log(studentForm)
        return {
            value: valueOverRide ? valueOverRide : studentForm.values[fieldName],
            id: fieldName,
            name: fieldName,
            onChange: onChangeOverRide ? onChangeOverRide : studentForm.handleChange,
            error: studentForm.touched[fieldName] && Boolean(studentForm.errors[fieldName]),
            helperText: studentForm.touched[fieldName] && studentForm.errors[fieldName]
        }
    }




    return (
        <Paper elevation={2}>
            <Typography variant="h6" gutterBottom component="div" xs={12} paddingLeft="10px">
                General Information
            </Typography>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <form onSubmit={studentForm.handleSubmit}>
                    <Grid container direction="row" className="paper-content" spacing={1}>
                        <Grid item xs={12} md={4}>
                            <Grid container direction="column" alignItems="center">
                                <Grid item xs={12} >
                                    <Avatar src={introIMg} sx={{width: 250, height: 250}}/>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item md={8} xs={12}>
                            <Grid container spacing={3} direction="row">
                                <Grid item xs={12} md={3}>
                                    <TextField
                                        fullWidth
                                        label="First Name"
                                        value={studentForm.values["firstName"]}
                                    />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <TextField
                                        fullWidth
                                        label="Last Name"
                                        {...formSetup("lastName")}
                                    />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <MobileDatePicker
                                        {...formSetup("dateOfBirth")}
                                        label="Date of Birth"
                                        format="MM/dd/yyyy"

                                        onChange={value => studentForm.setFieldValue("dateOfBirth", value)}
                                        renderInput={(params) => <TextField {...params}    fullWidth/>}
                                    />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <Grid container spacing={3} direction={"column"}>
                                        <Grid item>
                                            <TextField
                                                fullWidth
                                                label={"Street Address"}
                                                {...formSetup("address.street")}
                                                value={studentForm.values.address.street}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <TextField
                                                fullWidth
                                                label={"Line Two"}
                                                {...formSetup("address.lineTwo")}
                                                value={studentForm.values.address.lineTwo}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <TextField
                                                fullWidth
                                                label={"City"}
                                                {...formSetup("address.city")}
                                                value={studentForm.values.address.city}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <TextField
                                                fullWidth
                                                label={"State"}
                                                {...formSetup("address.state")}
                                                value={studentForm.values.address.state}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <TextField
                                                fullWidth
                                                label={"Zip-Code"}
                                                {...formSetup("address.zipCode")}
                                                value={studentForm.values.address.zipCode}
                                            />
                                        </Grid>



                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={5}>
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                multiline
                                                minRows={13}
                                                maxRows={13}
                                                label="General Notes"
                                                {...formSetup("generalNotes")}
                                            />

                                        </Grid>

                                    </Grid>

                                </Grid>
                            </Grid>

                        </Grid>

                    </Grid>
                </form>
            </LocalizationProvider>
        </Paper>
    )


}