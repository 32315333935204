import {Grid} from "@mui/material";
import WelcomeNav from "./WelcomeNav";

import './os-pub.css'
import WelcomeBanner from "./WelcomeBanner";
import {Hub} from "aws-amplify";

const Welcome = (props) => {

    const listener = (data) => {
        // eslint-disable-next-line
        switch (data.payload.event) {
            case 'signIn':
                props.setIsAuthed(true);
                break;
            case 'signUp':
                break;
            case 'signOut':
                props.setIsAuthed(false)
                break;
            case 'signIn_failure':
                break;
            case 'tokenRefresh':
                break;
            case 'tokenRefresh_failure':
                break;
            case 'configured':
        }
    }
    Hub.listen('auth', listener);
    return (
        <Grid container direction="column" lg={12}>
            <Grid item>
                <WelcomeNav isAuthed={props.isAuthed}/>
            </Grid>
            <Grid container direction="column" lg={12}>
                <Grid item>
                    <WelcomeBanner/>
                </Grid>
            </Grid>
        </Grid>

    )
}

export default Welcome;