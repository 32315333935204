import './App.css';
import Amplify, {Auth} from 'aws-amplify';
import awsconfig from './aws-exports'
import {
    BrowserRouter,
    Routes,
    Route
} from "react-router-dom";
import {Grid} from "@mui/material";
import Welcome from "./pages/public/Welcome";
import OwnSpectrum from "./pages/secured/OwnSpectrum";
import {useEffect, useState} from "react";
import HomeContainer from "./pages/secured/home/HomeContainer";
import StudentsSummaryContainer from "./pages/secured/students/StudentSummaries/StudentsSummaryContainer";
import StudentInfoContainer from "./pages/secured/students/StudentInfo/StudentInfoContainer";
import PageNotFound from "./pages/secured/PageNotFound";

Amplify.configure(awsconfig);


function App() {
    const [isAuthed, setIsAuthed] = useState(false);
    const [userInfo, setUserInfo] = useState()




    useEffect(() => {
        Auth.currentAuthenticatedUser()
            .then((user) => {
                    setUserInfo({user, isSignedIn: true ,jwtToken:user.signInUserSession.accessToken.jwtToken , idToken: user.signInUserSession.idToken.jwtToken})
                    setIsAuthed(true)
            }
            )
            .catch(() => {})
    }, [])


    return (
        <Grid>
            <Grid item>
                <BrowserRouter>
                    <Routes>
                        <Route path="/" exact element={<Welcome isAuthed={isAuthed} setIsAuthed={setIsAuthed}
                                                                someOtherValue={"mike"}/>}/>
                        <Route path="/myown" element={<OwnSpectrum setIsAuthed={setIsAuthed}/>}>
                            <Route path="home" element={<HomeContainer userInfo={userInfo}/>}/>
                            <Route path="students" element={<StudentsSummaryContainer userInfo={userInfo}/>}/>
                            <Route path="student/:studentId"  element={<StudentInfoContainer userInfo={userInfo}/>} />
                        </Route>
                        <Route path="*"  element={<PageNotFound/>} />
                    </Routes>
                </BrowserRouter>
            </Grid>
        </Grid>
    );
}

export default App;
