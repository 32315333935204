import {Grid, Typography} from "@mui/material";
import introIMg from '../../imgs/intro1.png'

const WelcomeBanner = () => {

    return(
        <Grid container direction="row" lg={12} className="welcome-banner">
            <Grid container  direction="column" alignItems="center" className="welcome-banner-text-area" justifyContent="center" lg={6} xs={12} >
           <Grid item>
               <Typography  variant="welcome-text" className="welcome-banner-text-desc"  >
                   Planning
               </Typography>
           </Grid>
                <Grid item>
                    <Typography  variant="welcome-text" className="welcome-banner-text-desc"  >
                        Execution &  Piece of Mind
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography  variant="welcome-text" className="welcome-banner-text-desc"  >
                          with all services for ASD.
                    </Typography>
                </Grid>
                </Grid>
            <Grid item lg={6} xs={12} >
                <img src={introIMg} className="responsive-img" alt=""/>
            </Grid>
        </Grid>
    )


}

export default WelcomeBanner;