import '../../App.css';
import Amplify from 'aws-amplify';
import awsconfig from '../../aws-exports'
import {withAuthenticator} from '@aws-amplify/ui-react'
import {Grid} from "@mui/material";
import OsNavContainer from "./nav/OsNavContainer";
Amplify.configure(awsconfig);

function OwenSpectrum({userInfo}) {

    return (
        <Grid>
            <OsNavContainer userInfo={userInfo}/>
            <Grid item>
            </Grid>

        </Grid>
    );
}

export default withAuthenticator(OwenSpectrum);
